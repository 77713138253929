import React from 'react';
import { Blank } from 'grommet-icons';

const IconWallet = (props) => {
  return (
    <Blank
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 47 47"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      {...props}
    >
      <path d="M31.022.76L.013 15.841l15.152 30.977 31.01-15.08L31.022.76zM2.69 16.763L30.102 3.431l13.395 27.385-27.412 13.331L2.69 16.763z" />
      <path d="M28.039 9.271c-2.334-.849-4.918.356-5.768 2.69-.849 2.334.356 4.918 2.69 5.768 2.334.849 4.918-.356 5.768-2.69.849-2.334-.356-4.918-2.69-5.768zm-.684 1.88c1.297.472 1.966 1.907 1.494 3.204-.472 1.297-1.907 1.966-3.204 1.494-1.297-.472-1.966-1.907-1.494-3.204.472-1.297 1.907-1.966 3.204-1.494zm.684-1.88l-.909-.33.909.33z" />
    </Blank>
  );
};

export default IconWallet;
